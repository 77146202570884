import PropTypes from 'prop-types';
import React from 'react';

import { getSeoConfig } from '../../../../constants/defaultSeoProps';
import DevSecOpsServiceHeroImage from '../../../../assets/services/DevSecOpsServiceHeroImage.png';
import Layout from '../../../../components/Layout';
import PageHeader from '../../../../components/PageHeader';
import ServiceSection from '../../../../components/ServiceSection';
import s from './DevSecOps.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  approachDetails: PropTypes.shape({}).isRequired,
  lifecycleDetails: PropTypes.shape({}).isRequired,
  techStackDetails: PropTypes.shape({}).isRequired,
  implementationProcessDetails: PropTypes.shape({}).isRequired,
  keyBenefitsDetails: PropTypes.shape({}).isRequired,
  gettingStartedDetails: PropTypes.shape({}).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/devsecops.png',
  ogTitle: 'DevSecOps Implementation Services | Tech Holding',
  ogDesc:
    'Build security into your development pipeline without compromising speed. Automated security testing, container security, and secure coding practices.',
  ogPath: '/services/security/devsecops',
};

const updatedSeo = getSeoConfig(seoParams);

const Security = (props) => {
  const {
    title,
    description,
    approachDetails,
    lifecycleDetails,
    techStackDetails,
    implementationProcessDetails,
    keyBenefitsDetails,
    gettingStartedDetails,
    companyQuote,
  } = props;

  return (
    <Layout
      currentPage="/services/security/devsecops"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      {/* Header */}
      <PageHeader title={title} description={description} />

      <div className={s.pageContainer}>
        {/* Hero Image */}
        <img src={DevSecOpsServiceHeroImage} alt={title} />

        {/* DevSecOps Philosophy */}
        <div className={s.approachSection}>
          <ServiceSection {...approachDetails} />
        </div>

        {/* Secure Development Lifecycle */}
        <ServiceSection {...lifecycleDetails} />

        {/* Technical Stack */}
        <ServiceSection {...techStackDetails} />

        {/* Implementation Process */}
        <ServiceSection {...implementationProcessDetails} />

        {/* Key Benefits */}
        <ServiceSection {...keyBenefitsDetails} />

        {/* Getting Started */}
        <ServiceSection {...gettingStartedDetails} />
      </div>
    </Layout>
  );
};

Security.propTypes = propTypes;
export default Security;
