import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import DevSecOps from '../../../containers/Services/Security/DevSecOps';

const title = 'DevSecOps';
const description =
  'Modern software development demands security practices that keep pace with rapid delivery cycles. Our DevSecOps approach integrates security seamlessly into your development workflow, enabling you to ship secure code faster without compromising on quality or speed.';
const approachDetails = {
  title: 'Our DevSecOps Philosophy',
  description:
    "We believe security should be a force multiplier for development teams, not a bottleneck. By shifting security left and embedding it throughout the software development lifecycle, we help teams catch and fix security issues early when they're least expensive to remediate.",
};
const lifecycleDetails = {
  title: 'Secure Development Lifecycle',
  topics: [
    {
      title: 'Design Phase',
      startingText: 'We help teams build security into their applications from the ground up:',
      points: [
        'Threat modeling workshops',
        'Security architecture review',
        'Security requirements definition',
        'API security design',
        'Identity and access management planning',
      ],
    },
    {
      title: 'Development Phase',
      startingText: 'Our tools and practices help developers write secure code from the start:',
      points: [
        'IDE security plugins',
        'Pre-commit hooks',
        'Code security guidelines',
        'Secure coding workshops',
        'Security unit testing',
      ],
    },
    {
      title: 'Build Phase',
      startingText: 'We implement automated security checks in your CI pipeline:',
      points: [
        'SAST (Static Application Security Testing)',
        'Software composition analysis',
        'Container security scanning',
        'Infrastructure as Code security checks',
        'Secrets detection',
      ],
    },
    {
      title: 'Test Phase',
      startingText: 'We validate security before deployment:',
      points: [
        'DAST (Dynamic Application Security Testing)',
        'API security testing',
        'Penetration testing',
        'Security regression testing',
        'Compliance validation',
      ],
    },
    {
      title: 'Deploy Phase',
      startingText: 'We ensure secure deployment practices:',
      points: [
        'Infrastructure hardening',
        'Configuration validation',
        'Secure secrets management',
        'Access control enforcement',
        'Deployment security gates',
      ],
    },
    {
      title: 'Runtime Phase',
      startingText: 'We maintain security during operation:',
      points: [
        'Runtime application protection',
        'Container security monitoring',
        'Cloud security posture management',
        'Continuous vulnerability assessment',
        'Security monitoring and alerting',
      ],
    },
  ],
};
const techStackDetails = {
  title: 'Our Technical Stack',
  topics: [
    {
      title: 'Source Code Security',
      points: [
        'SonarQube for code quality and security',
        'Grype, Trivy for dependency scanning',
        'Trivy for secrets detection',
        'Semgrep for custom security rules',
        'Checkov for IaC security',
      ],
    },
    {
      title: 'Container Security',
      points: [
        'Grype, Trivy for container scanning',
        'Docker Bench for security',
        'Falco for runtime security',
        'Harbor for secure registry',
        'Aqua Security for container protection',
      ],
    },
    {
      title: 'Cloud Security',
      points: [
        'AWS Security Hub',
        'Azure Security Center',
        'Google Cloud Security Command Center',
        'Terraform security scanning',
      ],
    },
    {
      title: 'Application Security',
      points: [
        'OWASP ZAP for DAST',
        'API security testing with Burp Suite',
        'CloudFlare WAF',
        'Custom security headers',
        'Authentication and authorization frameworks',
      ],
    },
  ],
};
const implementationProcessDetails = {
  title: 'Implementation Approach',
  topics: [
    {
      title: '1. Assessment',
      points: [
        'Current security posture evaluation',
        'Tool chain analysis',
        'Pipeline security review',
        'Team capability assessment',
        'Gap analysis',
      ],
    },
    {
      title: '2. Implementation',
      points: [
        'Security tool integration',
        'Pipeline configuration',
        'Custom rule development',
        'Authentication implementation',
        'Monitoring setup',
      ],
    },
    {
      title: '3. Automation',
      points: [
        'Security scan automation',
        'Results aggregation',
        'Issue tracking integration',
        'Automated remediation',
        'Compliance checking',
      ],
    },
    {
      title: '4. Training',
      points: [
        'Secure coding practices',
        'Tool usage workshops',
        'Security awareness',
        'Incident response',
        'Threat modeling',
      ],
    },
  ],
};
const keyBenefitsDetails = {
  title: 'Key Benefits',
  topics: [
    {
      title: 'Speed Without Compromise',
      points: [
        'Automated security testing',
        'Early issue detection',
        'Integrated security tools',
        'Streamlined remediation',
        'Minimal manual intervention',
      ],
    },
    {
      title: 'Developer Empowerment',
      points: [
        'Security feedback in IDE',
        'Clear remediation guidance',
        'Security best practices',
        'Tool-driven workflows',
        'Automated fixes',
      ],
    },
    {
      title: 'Comprehensive Protection',
      points: [
        'Multi-layer security',
        'Full stack coverage',
        'Continuous assessment',
        'Proactive protection',
        'Compliance validation',
      ],
    },
    {
      title: 'Measurable Results',
      points: [
        'Security metrics',
        'Risk trending',
        'Coverage reporting',
        'Time to remediation',
        'Compliance status',
      ],
    },
  ],
};
const gettingStartedDetails = {
  title: 'Getting Started',
  description:
    "Our team can assess your current development practices and create a roadmap for implementing DevSecOps. We'll help you select and integrate the right tools, establish secure workflows, and train your team on security best practices.",
  topics: [
    {
      title: 'Success Metrics',
      startingText: 'We help you track key DevSecOps metrics:',
      points: [
        'Mean time to remediation',
        'Security debt trending',
        'Risk acceptance rates',
        'Security coverage',
        'Deployment security scores',
      ],
    },
  ],
  footerDescription:
    'Contact us to discuss how we can help your team build and deploy more secure applications while maintaining development velocity.',
};

const details = {
  title,
  description,
  approachDetails,
  lifecycleDetails,
  techStackDetails,
  implementationProcessDetails,
  keyBenefitsDetails,
  gettingStartedDetails,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const DevSecOpsPage = ({ data }) => (
  <DevSecOps {...details} companyQuote={data.contentfulCompanyQuote} />
);

DevSecOpsPage.propTypes = propTypes;

export default DevSecOpsPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
